const variables = {
    tablet_breakpoint_width: 768,
    desktop_breakpoint_width: 1024,
    desktop_max_width: 1440
};

export default variables;

export  const zIndices = {
    imageContainer: 1,
    prefabLogo: 2,
    thankYouLogo: 10
};